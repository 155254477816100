import React, { ReactNode, useContext } from 'react';
import Head from 'next/head';
import Script from 'next/script';
import Footer from '@sm/webassets/Footer';
import { MetricsTracker, PageMetricsProvider } from '@sm/metrics';
import { StaticContext } from '@sm/webassets/StaticContext';
import { L10nProvider, L10nProviderProps } from '@sm/intl';

export type SurveyBasePageProps = {
  legacyWeb: string;
  pageId: string;
  includeExperiments?: boolean;
  includeGTM?: boolean;
  pageMetricsAttributes?: object;
  translationData?: L10nProviderProps;
  children: ReactNode;
  includeFooter?: boolean;
};

const SurveyBasePage = ({
  children,
  pageId,
  pageMetricsAttributes,
  legacyWeb,
  translationData,
  includeExperiments = false,
  includeGTM = false,
  includeFooter = false,
}: SurveyBasePageProps): JSX.Element => {
  const { user, environment, 'client-config': clientConfig, GDPR } = useContext(StaticContext);
  const { countryCode: country } = environment;
  const { assetsHost, gtmId, amplitudeToken, amplitudeForceAnonymous, dataAnalyticsAPIPath, loggingAPIPath } =
    clientConfig;
  const { domain } = environment;

  // For Metrics
  MetricsTracker.initialize({
    user,
    dataAnalyticsAPIPath,
    loggingAPIPath,
    country,
    legacyWeb,
    pageId,
    gtmId,
    amplitudeToken,
    // always fallback to anonymous events if not explicitly told otherwise
    forceAnonymous: amplitudeForceAnonymous || true,
  });

  return (
    <L10nProvider {...translationData}>
      <PageMetricsProvider pageId={pageId} attributes={pageMetricsAttributes} GDPR={GDPR} user={user} country={country}>
        <Head>
          <link rel="apple-touch-icon" href="/apple-touch-icon-precomposed.png" />
          <link rel="apple-touch-icon" sizes="57x57" href="/apple-touch-icon-57x57-precomposed.png" />
          <link rel="apple-touch-icon" sizes="60x60" href="/apple-touch-icon-60x60-precomposed.png" />
          <link rel="apple-touch-icon" sizes="72x72" href="/apple-touch-icon-72x72-precomposed.png" />
          <link rel="apple-touch-icon" sizes="76x76" href="/apple-touch-icon-76x76-precomposed.png" />
          <link rel="apple-touch-icon" sizes="114x114" href="/apple-touch-icon-114x114-precomposed.png" />
          <link rel="apple-touch-icon" sizes="120x120" href="/apple-touch-icon-120x120-precomposed.png" />
          <link rel="apple-touch-icon" sizes="144x144" href="/apple-touch-icon-144x144-precomposed.png" />
          <link rel="apple-touch-icon" sizes="152x152" href="/apple-touch-icon-152x152-precomposed.png" />
          <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon-180x180-precomposed.png" />
          <link rel="shortcut icon" href={`//${assetsHost}/assets/static/images/${domain}/favicon.ico`} />
        </Head>
        {children}
        {includeFooter ? <Footer domain={domain} /> : null}
      </PageMetricsProvider>
      {includeGTM && (
        <Script id="gtm" strategy="afterInteractive">
          {`(function (w, d, s, l, i) {w[l] = w[l] || [];w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });var f = d.getElementsByTagName(s)[0],j = d.createElement(s),dl = l != 'dataLayer' ? '&l=' + l : '';j.async = true;j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;f.parentNode.insertBefore(j, f);})(window, document, 'script', 'dataLayer', '${gtmId}');`}
        </Script>
      )}
    </L10nProvider>
  );
};

export default SurveyBasePage;
